<template>
  <div class="msg-block-container">
		<div class="msg-block" :class="type">
      <div class="icon">
        <i class="fa" :class="setIcon()"></i>
      </div>
      <div class="detail">{{ msg }}</div>
    </div>
	</div>
</template>

<script>
export default {
  props: {
    // is_show: Boolean,
    msg: String,
    type: {
      type: String,
      default: 'success'
    }
  },
  methods: {
    setIcon() {
      let icon = 'info';
      switch(this.type) {
        case 'warning':
          icon = 'warning';
          break;
        case 'error':
          icon = 'close';
          break;
        case 'success':
          icon = 'check';
          break;
      }
      return 'fa-'+icon;
    }
  }
}
</script>

<style scoped>
.msg-block {
	position: relative;
	margin-bottom: 13px;
	border-radius: 20px;
	background: #fff;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	overflow: hidden;
}
.msg-block-container .msg-block:last-child {
	margin-bottom: 28px;
}
.msg-block .icon {
	position: absolute;
	top: 0;
	left: 0;
	width: 36px;
	height: 100%;
	background: #32aed7;
	text-align: center;
	color: #fff;
}
.msg-block .icon i {
	margin: 6px 0 0 3px;
	font-size: 18px;
}
.msg-block.success .icon {
	background: #70c808;
}
.msg-block.warning .icon {
	background: #ff8400;
}
.msg-block.error .icon {
	background: #c73333;
}
.msg-block .title {
	display: inline-block;
	margin-right: 5px;
	color: #0099cc;
}
.msg-block.success .title {
	color: #669900;
}
.msg-block.warning .title {
	color: #cd5f15;
}
.msg-block.error .title {
	color: #990000;
}
.msg-block .detail {
  padding: 3px 50px;
}
/* .msg-block .close {
	display: block;
	position: absolute;
	top: 7px;
	right: 10px;
	width: 18px;
	height: 18px;
	opacity: 0.6;
	filter: alpha(opacity=50);
}
.msg-block .close:hover {
	opacity: 1.0;
	filter: alpha(opacity=100);
} */
</style>