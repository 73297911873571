<template>
  <label>
    {{ text }}
    <span v-if="required" class="required">*</span>
  </label>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: 'Label'
    },
    required: {
      type: Boolean,
      default: false
    }
  }
}
</script>