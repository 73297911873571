<template>
  <div v-if="getLoaded()" class="duck-form form-width-large form-center">
    <NotiBar v-if="msg.show" :type="msg.type" :msg="msg.text" />
    
    <form @submit.prevent="saveForm">

      <fieldset class="form-block">
        <div class="h-title">
          <h3>เนื้อหาบทความ</h3>
        </div>

        <div class="form-container">
          <div class="input">
            <Label text="หัวข้อของป้าย" />
            <input type="text" v-model="contentData.title">
          </div>
          
          <div class="input">
            <Label text="ค่าลำดับ" />
            <input type="number" v-model="contentData.priority" class="very-extra-small" min="0">
            <p class="input-description">ระบบจะเรียงจากค่ามากจะแสดงก่อนและค่าน้อยจะแสดงทีหลัง</p>
          </div>
          
          <div class="input">
            <Label text="ลิ้งค์" />
            <input type="text" v-model="contentData.meta.url">
          </div>

          <div class="input">
            <Label text="รูปภาพประกอบ" />
            <SingleImageUploader type="thumbnail" section="content" :parentId="contentData.ID" description="ใช้รูปขนาดเท่าใดก็ได้ (แนะนำความกว้าง/สูงไม่เกิน 300 pixel เพื่อลดการโหลดหน้าเว็บไซต์)" />
          </div>
        </div>
      </fieldset>

      <div class="button-block">
        <button type="submit" class="btn btn-update">
          <i class="fa fa-save fa-lg"></i> บันทึกข้อมูล
        </button>
      </div>
    </form>
  </div>
</template>


<script>
// @ is an alias to /src
import {apiRequest} from '@/utils/axios/axiosInstance.js';

import NotiBar            from '@/components/common/NotiBar.vue';
import Label              from '@/components/common/form/Label.vue';  
import SingleImageUploader from '@/components/file/SingleImageUploader.vue';

export default {
  data() {
    return {
      loaded: false,
      msg: {
        show: false,
        type: 'success',
        text: '',
      },

      contentId: '',
      contentData: {},
      isEditForm: false,
    }
  },
  components: {
    Label,
    NotiBar,
    SingleImageUploader,
  },
  created() {
    this.contentId = this.$route.params.id;

    this.$store.commit('setPageTitle', 'Edit Partner Logo');
    this.$store.commit('setHeadTitle', 'Edit Partner Logo');

    this.loadPage();
  },
  methods: {
    getLoaded() {
      return !this.$store.state.show_loader;
    },
    loadPage:function() {
      this.$store.commit('setLoaderShow', true);
      apiRequest
        .get('/content/post-item/'+this.contentId)
        .then( (res) => {
          if(res.data.status==200) {
            this.contentData = res.data.result;

            if(this.contentData.status=='new' || this.contentData.status=='trash') {
              this.isEditForm = false;
              this.contentData.status = 'draft';
            }else{
              this.isEditForm = true;
            }


          
            // if not meta data (when new post), system will set default data
            if(Object.keys(this.contentData.meta).length==0) {
              this.contentData.meta = {
                url: ''
              };
            }
            
            // hide page loading
            this.$store.commit('setLoaderShow', false);
          }
        });
    },
    saveForm:function() {
      this.$store.commit('setLoaderShow', true);

      // is alway 'publish' status
      this.contentData.status = 'publish';

      apiRequest
        .post('/content/save-post', this.contentData)
        .then( (res) => {
          this.msg.show = true;
          if(res.data.status==200) {
            this.msg.text = 'Save data complete';

            // force change this page to Edit Form
            this.isEditForm = true;
          }else{
            this.msg.type = 'error';
            this.msg.text = 'Save data fail. Please, try again.';
          }
          this.$store.commit('setLoaderShow', false);
        });
    },
  }
}
</script>

<style scoped>
.col-thumb {padding-right: 15px;}

.preview-link {
  margin-top: 5px;
}
.select-time {
  padding-left: 5px;
  width: 45px;
}
</style>